<template>
  <select
    aria-label="country select box"
    region-selector
    :style="{
      padding: '5px 8px',
      border: '1px solid #CCC',
      width: '100%',
      height: '100%',
      outline: 0,
      'border-top-left-radius': '6px',
      'border-bottom-left-radius': '6px',

      ...(alpha2.length === 0 ? { color: '#aaa' } : {})
    }"
    @change="update"
    @focus="onFocus"
    @blur="onBlur"
  >
    <option disabled selected :value="null" hidden>country</option>

    <option
      v-for="region in regions"
      :key="region.alpha2"
      :value="region.alpha2"
      :selected="alpha2.length > 0 && region.alpha2 === alpha2"
      >{{ region.name && region.name.length > 0 ? region.name : '' }}</option
    >
  </select>
</template>

<style lang="scss" scoped>
option {
  background: white;
  color: black;
}
</style>

<script>
import { findRegion } from '@/utilities/phoneNumber/findRegion'

export default {
  props: {
    regions: {
      type: Array,
      default: () => {
        return []
      }
    },
    onUpdate: Function,
    onFocus: Function,
    onBlur: Function,
    selectedAlpha2: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      alpha2: ''
    }
  },
  methods: {
    update(event) {
      this.alpha2 = event.target.value

      const region = findRegion({ alpha2: this.alpha2 })

      this.onUpdate(region)
    }
  },
  watch: {
    selectedAlpha2(alpha2) {
      this.alpha2 = alpha2
    }
  }
}
</script>
