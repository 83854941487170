var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tooltip',{attrs:{"label":"extension (optional)"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.num),expression:"num"}],ref:"input",style:({
      border: '1px solid #CCC',
      padding: '4px 8px',
      outline: 0,
      'border-top-right-radius': '6px',
      'border-bottom-right-radius': '6px',
      borderLeft: 0,
      width: '100%',
      height: '100%'
    }),attrs:{"extension-input":"","placeholder":"ext (optional)"},domProps:{"value":(_vm.num)},on:{"keyup":function($event){return _vm.onKeyUp(_vm.num)},"focus":_vm.onFocus,"blur":_vm.onBlur,"keydown":function (e) {
        _vm.lastKeyPressed = e.keyCode
      },"input":function($event){if($event.target.composing){ return; }_vm.num=$event.target.value}}})])}
var staticRenderFns = []

export { render, staticRenderFns }