<template>
  <b-tooltip label="extension (optional)">
    <input
      extension-input
      ref="input"
      :style="{
        border: '1px solid #CCC',
        padding: '4px 8px',
        outline: 0,
        'border-top-right-radius': '6px',
        'border-bottom-right-radius': '6px',
        borderLeft: 0,
        width: '100%',
        height: '100%'
      }"
      v-model="num"
      @keyup="onKeyUp(num)"
      @focus="onFocus"
      @blur="onBlur"
      @keydown="
        e => {
          lastKeyPressed = e.keyCode
        }
      "
      placeholder="ext (optional)"
    />
  </b-tooltip>
</template>

<style lang="scss" scoped>
.b-tooltip {
  height: 100%;
}
</style>

<script>
export default {
  props: {
    onUpdate: Function,
    onFocus: Function,
    onBlur: Function,
    example: {
      type: String,
      default: ''
    },
    alpha2: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      num: '',
      previous: '',
      parsed: '',
      lastKeyPressed: null
    }
  },
  watch: {
    num(current, previous) {
      this.previous = previous
    }
  },
  methods: {
    setValue(num) {
      this.num = num
    },
    onKeyUp(num) {
      this.num = num
      this.onUpdate(num)
    }
  }
}
</script>
