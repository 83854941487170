var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.num),expression:"num"}],ref:"input",style:(Object.assign(
      {},
      {
        border: '1px solid #CCC',
        padding: '4px 8px',
        outline: 0,
        borderLeft: 0,
        width: '100%',
        height: '100%'
      },
      Object.assign({}, (!_vm.allowExtension
          ? {
              'border-top-right-radius': '6px',
              'border-bottom-right-radius': '6px'
            }
          : {}))
    )),attrs:{"regional-number":"","aria-label":'national number' +
      (typeof _vm.example === 'string' && _vm.example.length > 0 ? 'for example ' + _vm.example : ''),"placeholder":"national number"},domProps:{"value":(_vm.num)},on:{"keyup":function($event){return _vm.onKeyUp(_vm.num)},"focus":_vm.onFocus,"blur":_vm.onBlur,"keydown":function (e) {
      _vm.lastKeyPressed = e.keyCode
    },"input":function($event){if($event.target.composing){ return; }_vm.num=$event.target.value}}})}
var staticRenderFns = []

export { render, staticRenderFns }