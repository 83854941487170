/*
  import { findRegion } from '@/utilities/phoneNumber/findRegion'
*/

import { countries } from '@/utilities/phoneNumber/countries'

export const findRegion = ({ alpha2 }) => {
  for (let a = 0; a < countries.length; a++) {
    if (countries[a].alpha2 === alpha2) {
      return countries[a]
    }
  }

  return null
}
