<template>
  <section :style="{ background: '#fff', padding: '20px' }">
    <b>Phone number with country set as US</b>

    <div :style="{ width: '100%', padding: '100px 0' }">
      <PhoneInput :e164.sync="e164" />
    </div>

    <div
      :style="{
        padding: '20px',
        border: '1px solid #CCC',
        borderRadius: '4px',
        marginBottom: '12px'
      }"
    >
      e164: <span e164>{{ e164 }}</span>
    </div>

    <b-button @click="updateNumber" update-number-button>update number</b-button>
    <b-input v-model="inputNumber" update-number-input />
  </section>
</template>

<script>
import PhoneInput from '@/components/inputs/Phone'

export default {
  components: {
    PhoneInput
  },
  data() {
    return {
      value: '',
      e164: '',
      results: '',
      resultsTable: '',

      inputNumber: '+18005550000'
    }
  },
  methods: {
    onUpdate: function(payload) {
      this.results = payload
      this.resultsTable = Object.keys(payload)
    },
    updateNumber: function() {
      this.e164 = this.inputNumber
    }
  }
}
</script>
