<template>
  <input
    regional-number
    ref="input"
    :aria-label="
      'national number' +
        (typeof example === 'string' && example.length > 0 ? 'for example ' + example : '')
    "
    :style="
      Object.assign(
        {},
        {
          border: '1px solid #CCC',
          padding: '4px 8px',
          outline: 0,
          borderLeft: 0,
          width: '100%',
          height: '100%'
        },
        {
          ...(!allowExtension
            ? {
                'border-top-right-radius': '6px',
                'border-bottom-right-radius': '6px'
              }
            : {})
        }
      )
    "
    v-model="num"
    @keyup="onKeyUp(num)"
    @focus="onFocus"
    @blur="onBlur"
    @keydown="
      e => {
        lastKeyPressed = e.keyCode
      }
    "
    placeholder="national number"
  />
</template>

<script>
import { AsYouType } from 'libphonenumber-js'
import { getDigits } from '@/utilities/phoneNumber/getDigits'

export default {
  props: {
    allowExtension: {
      type: Boolean,
      default: false
    },
    onUpdate: Function,
    onFocus: Function,
    onBlur: Function,
    example: {
      type: String,
      default: ''
    },
    alpha2: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      num: '',
      previous: '',
      parsed: '',
      lastKeyPressed: null
    }
  },
  watch: {
    num(current, previous) {
      this.previous = previous
    }
  },
  methods: {
    setRegion(region) {
      this.num = new AsYouType(region).input(this.num)
    },
    setValue(num, country) {
      if (country === 'US') {
        this.num = new AsYouType('US').input(num)
      } else {
        this.num = num
      }
    },
    onKeyUp(num) {
      const previousDigits = getDigits(this.previous)
      const currentDigits = getDigits(num)

      const backSpacePressed = this.lastKeyPressed === 8
      const input = this.$refs.input

      /*
        (800)| 4 -> (804)
          cursor: 5

        (800) |4 -> (804)
          cursor: 6

        (800)| -> 80
      */
      if (this.alpha2 === 'US') {
        const numbers = '0123456789'
        let index = null

        // if backspace didn't erase a digit,
        // erase the first digit to the left
        // of the cursor, if there is one
        if (backSpacePressed && currentDigits === previousDigits) {
          let cursor = input.selectionStart
          while (cursor >= 1) {
            const leftCharacter = num.substring(cursor - 1, cursor)

            index = numbers.indexOf(leftCharacter)
            if (index >= 0) {
              num = num.substring(0, cursor - 1) + num.substring(cursor)
              break
            }

            cursor--
          }
        }

        this.num = new AsYouType('US').input(num)
      }

      this.onUpdate(getDigits(this.num))
    }
  }
}
</script>
