/*
  import { getDigits } from '@/utilities/phoneNumber/getDigits'
*/

const digits = {
  0: true,
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true
}

export const getDigits = num => {
  let result = ''

  for (let a = 0; a < num.length; a++) {
    if (digits[num[a]] === true) {
      result += num[a]
    }
  }

  return result
}
