var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tooltip',{attrs:{"label":"country code"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],style:({
      border: '1px solid #CCC',
      borderLeft: 0,
      padding: '4px 8px',
      outline: 0,
      width: '100%',
      height: '100%'
    }),attrs:{"region-input":"","placeholder":"country code"},domProps:{"value":(_vm.code)},on:{"keyup":function($event){return _vm.onKeyUp(_vm.code)},"focus":_vm.onFocus,"blur":_vm.onBlur,"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}})])}
var staticRenderFns = []

export { render, staticRenderFns }