var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{style:({ background: '#fff', padding: '20px' })},[_c('b',[_vm._v("Phone number with country set as US")]),_c('div',{style:({ width: '100%', padding: '100px 0' })},[_c('PhoneInput',{attrs:{"extension":_vm.extension,"e164":_vm.e164},on:{"update:extension":function($event){_vm.extension=$event},"update:e164":function($event){_vm.e164=$event}}})],1),_c('div',{style:({
      padding: '20px',
      border: '1px solid #CCC',
      borderRadius: '4px',
      marginBottom: '12px'
    })},[_vm._v(" e164: "),_c('span',{attrs:{"e164":""}},[_vm._v(_vm._s(_vm.e164))])]),_c('div',{style:({
      padding: '20px',
      border: '1px solid #CCC',
      borderRadius: '4px',
      marginBottom: '12px'
    })},[_vm._v(" extension: "),_c('span',{attrs:{"extension":""}},[_vm._v(_vm._s(_vm.extension))])]),_c('b-button',{attrs:{"update-number-button":""},on:{"click":_vm.updateNumber}},[_vm._v("update number")]),_c('b-input',{attrs:{"update-number-input":""},model:{value:(_vm.inputNumber),callback:function ($$v) {_vm.inputNumber=$$v},expression:"inputNumber"}}),_c('br'),_c('b-button',{attrs:{"update-ext-button":""},on:{"click":_vm.updateExtension}},[_vm._v("update extension")]),_c('b-input',{attrs:{"update-ext-input":""},model:{value:(_vm.extensionNumber),callback:function ($$v) {_vm.extensionNumber=$$v},expression:"extensionNumber"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }