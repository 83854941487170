<template>
  <div :style="{ padding: '100px 100px' }" role="main">
    <h1>Phone Input</h1>

    <br />

    <b-tabs v-model="activeTab">
      <b-tab-item label="Blank">
        <Blank />
      </b-tab-item>

      <b-tab-item label="Blank with extension">
        <BlankWithExtension />
      </b-tab-item>

      <b-tab-item label="MX">
        <MX />
      </b-tab-item>

      <b-tab-item label="with problem">
        <WithProblem />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import MX from './components/MX'
import Blank from './components/Blank'
import BlankWithExtension from './components/BlankWithExtension'
import WithProblem from './components/WithProblem'

export default {
  components: {
    MX,
    Blank,
    BlankWithExtension,
    WithProblem
  },
  data() {
    return {
      activeTab: 1
    }
  }
}
</script>
