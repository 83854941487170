<template>
  <b-tooltip label="country code">
    <input
      region-input
      :style="{
        border: '1px solid #CCC',
        borderLeft: 0,
        padding: '4px 8px',
        outline: 0,
        width: '100%',
        height: '100%'
      }"
      v-model="code"
      @keyup="onKeyUp(code)"
      @focus="onFocus"
      @blur="onBlur"
      placeholder="country code"
    />
  </b-tooltip>
</template>

<style lang="scss" scoped>
.b-tooltip {
  height: 100%;
}
</style>

<script>
export default {
  props: {
    regionSelectorCode: {
      type: String,
      default: ''
    },
    onUpdate: Function,
    onFocus: Function,
    onBlur: Function
  },
  data() {
    return {
      code: ''
    }
  },
  watch: {
    regionSelectorCode(code) {
      this.code = code
    }
  },
  methods: {
    onKeyUp(code) {
      if (code !== this.regionSelectorCode) {
        this.onUpdate(code)
      }
    }
  }
}
</script>
