<template>
  <b-field :label="label" class="phone-number-input" phone-number-input>
    <table :style="{ width: '100%' }" :aria-label="ariaLabel" tabindex="0">
      <tbody>
        <tr
          :class="
            'phone-field--inputs' +
              (focused ? ' focused' : '') +
              (problemMessage.length > 0 ? ' problematic' : '')
          "
          :style="{
            borderRadius: '6px',
            height
          }"
        >
          <td :style="{ width: '230px', height }">
            <RegionSelector
              :regions="regions"
              :selectedAlpha2="regionSelectorAlpha2"
              :onUpdate="regionSelectorUpdated"
              :onFocus="onFocus"
              :onBlur="onBlur"
            />
          </td>
          <td :style="{ width: '110px', height }">
            <RegionInput
              :regionSelectorCode="regionInputCode"
              :onUpdate="regionInputUpdated"
              :onFocus="onFocus"
              :onBlur="onBlur"
            />
          </td>
          <td :style="{ width: '400px', height }">
            <RegionalNumber
              ref="regionalNumber"
              :allowExtension="allowExtension"
              :onUpdate="regionNumberUpdated"
              :example="regionalNumberExample"
              :alpha2="regionalNumberAlpha2"
              :onFocus="onFocus"
              :onBlur="onBlur"
            />
          </td>
          <td v-if="allowExtension" :style="{ width: '110px', height }">
            <Extension
              ref="extension"
              :allowExtension="allowExtension"
              :onFocus="onFocus"
              :onBlur="onBlur"
              :onUpdate="extensionUpdated"
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td v-if="regionalNumberExample" :style="{ paddingLeft: '8px', paddingTop: '3px' }">
            example: {{ regionalNumberExample }}
          </td>
          <td v-if="allowExtension"></td>
        </tr>
      </tbody>
    </table>
    <p class="problem-message" :style="{ padding: '5px 8px 0' }" problem-message>
      {{ problemMessage }}
    </p>
  </b-field>
</template>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.phone-field--inputs {
  &.focused {
    box-shadow: 0 4px 14px -5px rgba(0, 0, 0, 0.18), 0 0 1px 1px $primary;
  }

  &.problematic {
    box-shadow: 0 4px 14px -5px rgba(0, 0, 0, 0.18), 0 0 1px 1px $color-4;
  }
}

.problem-message {
  color: $color-4;
}
</style>

<style lang="scss">
.phone-number-input.field .field.has-addons {
  flex-direction: column !important;
}
</style>

<script>
/*
  plan:

    import PhoneInput from '@/components/inputs/Phone'

    <PhoneInput
      :allowExtension="true"
      :e164.sync=""
    />
*/

import _get from 'lodash/get'
//
import RegionSelector from './components/RegionSelector'
import RegionInput from './components/RegionInput'
import RegionalNumber from './components/RegionalNumber'
import Extension from './components/Extension'

import { countries } from '@/utilities/phoneNumber/countries'
//
import { getExampleNumber } from 'libphonenumber-js'
import regionalNumberExamples from 'libphonenumber-js/examples.mobile.json'
import parsePhoneNumber from 'libphonenumber-js'
//
export default {
  components: {
    Extension,
    RegionSelector,
    RegionInput,
    RegionalNumber
  },
  props: {
    problem: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    e164: {
      type: String,
      default: ''
    },
    extension: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      allowExtension: typeof this.extension === 'string',
      regions: countries,
      //
      regionSelectorCode: '',
      regionSelectorAlpha2: '',
      //
      regionInputCode: '',
      //
      regionalNumberAlpha2: '',
      regionalNumberExample: '',
      regionalNumber: '',
      //
      focused: false,
      internalE164: '',
      internalExtension: '',
      problemMessage: this.problem,

      ariaLabel: this.label || 'phone number input fields',

      height: '35px'
    }
  },
  watch: {
    extension(extension) {
      if (typeof extension === 'string') {
        this.allowExtension = true
      } else {
        this.allowExtension = false
      }

      if (this.internalExtension === extension) {
        // internal update
        return
      }

      this.$refs.extension.setValue(extension)
    },
    e164(e164) {
      this.problemMessage = ''

      if (this.internalE164 === e164) {
        // internal update
        return
      }

      this.addE164()
    }
  },
  methods: {
    addE164() {
      const parsed = parsePhoneNumber(this.e164)

      const country = _get(parsed, 'country', '')
      const nationalNumber = _get(parsed, 'nationalNumber', '')
      const countryCallingCode = _get(parsed, 'countryCallingCode', '')

      if (typeof country === 'string' && country.length > 0) {
        this.regionSelectorAlpha2 = country
        this.regionalNumberAlpha2 = country

        let example = ''
        try {
          example = getExampleNumber(
            this.regionSelectorAlpha2,
            regionalNumberExamples
          ).formatNational()
        } catch (exception) {
          console.error(exception)
        }
        this.regionalNumberExample = example
      }

      if (typeof countryCallingCode === 'string' && countryCallingCode.length > 0) {
        this.regionInputCode = countryCallingCode
      }

      if (typeof nationalNumber === 'string' && nationalNumber.length > 0) {
        this.$refs.regionalNumber.setValue(nationalNumber, country)
      }
    },
    onFocus() {
      this.focused = true
    },
    onBlur() {
      this.focused = false
    },
    extensionUpdated(extension) {
      this.internalExtension = extension
      this.$emit('update:extension', extension)
    },
    regionSelectorUpdated(region) {
      this.regionInputCode = _get(region, 'regionCode', '')
      this.regionSelectorAlpha2 = _get(region, 'alpha2', '')
      this.regionalNumberAlpha2 = _get(region, 'alpha2', '')

      var example = ''
      try {
        example = getExampleNumber(
          this.regionSelectorAlpha2,
          regionalNumberExamples
        ).formatNational()
      } catch (exception) {
        console.error(exception)
      }
      this.regionalNumberExample = example

      this.$refs.regionalNumber.setRegion(this.regionSelectorAlpha2)

      let e164 = '+' + this.regionInputCode + this.regionalNumber
      if (e164 === '+') {
        e164 = ''
      }

      this.internalE164 = e164
      this.$emit('update:e164', e164)
    },
    regionInputUpdated(code) {
      this.regionInputCode = code

      // clears the region selector
      this.regionSelectorAlpha2 = ''

      // clears the regional example
      this.regionalNumberExample = ''

      let e164 = '+' + code + this.regionalNumber
      if (e164 === '+') {
        e164 = ''
      }

      this.internalE164 = e164
      this.$emit('update:e164', e164)
    },
    regionNumberUpdated(num) {
      this.regionalNumber = num

      let e164 = '+' + this.regionInputCode + this.regionalNumber
      if (e164 === '+') {
        e164 = ''
      }

      this.internalE164 = e164
      this.$emit('update:e164', e164)
    }
  },
  mounted() {
    const e164 = this.e164
    if (typeof e164 === 'string' && e164.length > 0) {
      this.addE164()
    }

    const extension = this.extension
    if (typeof extension === 'string' && extension.length > 0) {
      this.$refs.extension.setValue(extension)
    }
  }
}
</script>
