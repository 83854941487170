var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('select',{style:(Object.assign({}, {padding: '5px 8px',
    border: '1px solid #CCC',
    width: '100%',
    height: '100%',
    outline: 0,
    'border-top-left-radius': '6px',
    'border-bottom-left-radius': '6px'},

    (_vm.alpha2.length === 0 ? { color: '#aaa' } : {}))),attrs:{"aria-label":"country select box","region-selector":""},on:{"change":_vm.update,"focus":_vm.onFocus,"blur":_vm.onBlur}},[_c('option',{attrs:{"disabled":"","selected":"","hidden":""},domProps:{"value":null}},[_vm._v("country")]),_vm._l((_vm.regions),function(region){return _c('option',{key:region.alpha2,domProps:{"value":region.alpha2,"selected":_vm.alpha2.length > 0 && region.alpha2 === _vm.alpha2}},[_vm._v(_vm._s(region.name && region.name.length > 0 ? region.name : ''))])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }